import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { generateQueryIsbnUrl, getSearchQueryString, parseMultipleGridIsbns } from "common/utils";
import { BULK_COMPARISON } from "common/constants/pathRoutes";
import { ACCENT } from "common/constants/buttonTypes";
import { SELL_STATE } from "common/constants/searchTypes";
import ModalTypes from "common/constants/modalTypes";
import { addErrorNotification } from "common/notifications";
import { useModalContext } from "contexts/Modal";
import { setIsScanning } from "contexts/App/actions";
import { useAppContext } from "contexts";
import { ReactComponent as GridIcon } from "public/icons/grid-icon.svg";
import * as S from "../../Header.styled";
const Tippy = dynamic(() => import("components/Tippy"), {
  ssr: false
});
const SearchField = ({
  searchValue,
  setSearchValue,
  activeTab
}) => {
  const searchRef = useRef(null);
  const router = useRouter();
  const [isMobileFlag, setIsMobileFlag] = useState(false);
  const [{
    isScanning
  }, dispatch] = useAppContext();
  const {
    modalAction
  } = useModalContext();
  const isSellTabActive = useMemo(() => activeTab === SELL_STATE, [activeTab]);
  const shouldDisplayGridIcon = useMemo(() => isSellTabActive && !router.asPath.includes(BULK_COMPARISON), [isSellTabActive, router.asPath]);
  useEffect(() => {
    setIsMobileFlag(isMobile);
  }, []);
  useEffect(() => {
    // set search focus by default and by history change
    if (!isMobile && searchRef.current) {
      searchRef.current.focus();
    }
  }, [router.asPath]);
  const handleSearchSubmit = ({
    isEnterClick
  }) => {
    const parsedIsbns = parseMultipleGridIsbns({
      isbns: searchValue,
      withNotification: false
    });
    if (isSellTabActive && parsedIsbns.length > 1) {
      router.push(`${BULK_COMPARISON}${generateQueryIsbnUrl({
        isbns: parsedIsbns
      })}`);
      return;
    }

    // check ref value for code scanners
    if (searchValue.length || searchRef.current.value.length) {
      isEnterClick && document.activeElement.blur();
      window.dataLayer.push({
        event: "search",
        type: "clickHeader"
      });
      const isText = searchValue.match(/[a-zA-Zа-яА-Я]/g);
      router.push(getSearchQueryString({
        searchValue: searchValue || searchRef.current.value,
        activeSearchTab: !isText && activeTab
      }));
    }
  };
  const handleMultipleIsbnModal = useCallback(() => {
    modalAction({
      type: ModalTypes.MULTIPLE_ISBNS,
      title: "Add multiple ISBNs",
      modalStyles: {
        maxWidth: "630px"
      }
    });
  }, []);
  const handleKeyDown = event => {
    event.key === "Enter" && handleSearchSubmit({
      isEnterClick: true
    });
  };
  const onCameraSearch = useCallback(async () => {
    if (navigator && !isScanning) {
      try {
        setIsScanning({
          dispatch,
          isScanning: true
        });
      } catch (err) {
        addErrorNotification({
          msg: err.message
        });
      }
    }
  }, [isScanning]);
  return <S.Container data-test-id="SearchField-Container">
            <S.SearchContainer>
                <S.SearchIconContainer>
                    <S.SearchIcon />
                </S.SearchIconContainer>
                <S.SearchWrapper>
                    <S.SearchInput ref={searchRef} type="text" value={searchValue} placeholder={"Search for ISBN number..."} onInput={event => setSearchValue(event.target.value)} onKeyPress={handleKeyDown} onChange={event => {
          setSearchValue(event.target.value);
        }} />
                    <S.CameraSearch data-test-id={"Scanner header button"} onClick={onCameraSearch} isMobile={isMobileFlag} />
                    {shouldDisplayGridIcon && <Tippy interactive content={<S.TooltipContent>Add multiple ISBNs</S.TooltipContent>} placement="bottom">
                            <S.SearchGridIconWrapper>
                                <GridIcon data-test-id={"Grid icon searchbox"} onClick={handleMultipleIsbnModal} />
                            </S.SearchGridIconWrapper>
                        </Tippy>}
                </S.SearchWrapper>
                <S.SearchButton data-test-id={"Header Search"} variant={ACCENT} label="SCOUT" onClick={handleSearchSubmit} />
            </S.SearchContainer>
        </S.Container>;
};
SearchField.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};
export default SearchField;